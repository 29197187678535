import React from 'react';
import footerStyle from './footer.module.css';
import copyrightIcon from '../../../static/assets/img/icon/C.png';


class Footer extends React.Component {
  render() {
    return (
      <footer className={footerStyle.footer}>
          <img src={copyrightIcon}></img> Copyright {new Date().getFullYear()}. Mimosa. All rights reserved. Privacy
      </footer>
    );
  }
}

export default Footer;
import React from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import headerStyles from './header.module.css';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { Link as GatsbyLink } from "gatsby";
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import logo from '../../../static/assets/img/logo/Logo-Fin.png';
import logoM from '../../../static/assets/img/logo/Logo-M.png';


const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: 'black'
      }
    },
    MuiButton: {
      text: {
        '&:hover': {
          color: `#f8e008`
        },
        color: 'white'
      }
    },
    MuiDrawer: {
      paper: {
        backgroundColor: 'black',
        width: '250px',
        padding: '20px',
        borderRight: '1px #f8e008 solid'
      }
    },
    MuiListItemText: {
      primary: {
        '&:hover': {
          color: `#f8e008`
        },
        color: 'white',
        textTransform: 'uppercase',
        fontFamily: 'Oswald',
        fontSize: '18px',
        fontWeight: '100',
        letterSpacing: '3px'
      }
    }
  }
});

const HeaderMenuButton = withStyles({
  label: {
    textTransform: 'uppercase',
    fontFamily: 'Oswald',
    fontSize: '18px',
    fontWeight: '100',
    letterSpacing: '3px'
  },
  text: {
    '&:hover': {
      color: `#f8e008`
    },
    color: 'white'
  }
})(Button);

const LeftDrawer = withStyles({
  paper: {
    backgroundColor: 'black',
    width: '250px',
    padding: '20px',
    borderRight: '1px #f8e008 solid'
  }
})(Drawer);

class Header extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      hideHeaderBg: false,
      openDrawer: false
    };
  }

  componentDidMount() {
    window.addEventListener('showHeaderBg', this.showHeaderBg);
    window.addEventListener('hideHeaderBg', this.hideHeaderBg);
  }
  
  componentWillUnmount() {
    window.removeEventListener('showHeaderBg', this.showHeaderBg);
    window.removeEventListener('hideHeaderBg', this.hideHeaderBg);
  }

  showHeaderBg = () => {
    this.setState({ hideHeaderBg: false });
  }

  hideHeaderBg = () => {
    this.setState({ hideHeaderBg: true });
  }

  toggleDrawer = (open) => () => {
    this.setState({
      openDrawer: open
    });
  }

  getLinkTo = (menu) => {
    let linkTo = "/";
    if (menu !== 'Home') {
      linkTo += menu.toLocaleLowerCase() + '/';
    }
    return linkTo;
  }

  render() {
    const menuList = ['Home', 'M3', 'Pricing', 'Contact'];
    const drawerMenuList = (
      <List>
        {
          menuList.map((text, index) => {
            return (
              <ListItem button key={index} component={GatsbyLink} to={this.getLinkTo(text)}>
                <ListItemText primary={text} className={`${headerStyles.listItemText}`}/>
              </ListItem>
            );
          })
        }
      </List>
    );
    const headerMenuList = (
      <Grid container spacing={8}>
        {
          menuList.map((text, index) => {
            return (
              <Grid key={index} item>
                <HeaderMenuButton component={GatsbyLink} to={this.getLinkTo(text)}
                >
                  {text}
                </HeaderMenuButton>
              </Grid>
            );
          })
        }
      </Grid>
    )
    const siteLogo = (
      <GatsbyLink to="/">
        <img
          src={logo}
          className={`${headerStyles.siteLogo}`}
          alt="Mimosa Solutions"
          title="Mimosa Solutions"
        />
      </GatsbyLink>
    );
    const siteLogoMobile = (
      <GatsbyLink to="/">
        <img
          src={logoM}
          className={`${headerStyles.siteLogo}`}
          alt="Mimosa Solutions"
          title="Mimosa Solutions"
        />
      </GatsbyLink>
    );
    const socialMedia = (
      <Grid container spacing={2} justify="center">
        <Grid item xs="auto">
          <Link
            href="https://www.facebook.com/Mimosa-Solutions-1802420579845859/"
            target="_blank"
            rel="noopener"
          >
            <div
              className={`${headerStyles.facebookIcon}`}
              alt="Facebook"
              title="Facebook"
            />
          </Link>
        </Grid>
        <Grid item xs="auto">
          <Link
            href="https://www.instagram.com/mimosa.solutions/"
            target="_blank"
            rel="noopener"
          >
            <div
              className={`${headerStyles.instagramIcon}`}
              alt="Instagram"
              title="Instagram"
            />
          </Link>
        </Grid>
        <Grid item xs="auto">
          <Link
            href="https://www.linkedin.com/company/mimosa-solutions/about"
            target="_blank"
            rel="noopener"
          >
            <div
              className={`${headerStyles.linkedinIcon}`}
              alt="Linkedin"
              title="Linkedin"
            />
          </Link>
        </Grid>
      </Grid>
    )

    return (
      <React.Fragment>
        <AppBar
          position="fixed"
          className={`${headerStyles.appBar} ${(this.state.hideHeaderBg) ? headerStyles.appBarScrollTop : ''}`}
          style={{background: 'black'}}
        >
          <Toolbar className={headerStyles.toolbar}>
            <Grid container spacing={0} justify="space-between" alignItems="center">
              <Grid item xs="auto">
                <Hidden xsDown>
                  {siteLogo}
                </Hidden>
                <Hidden smUp>
                  {siteLogoMobile}
                </Hidden>
              </Grid>
              <Hidden mdDown>
                <Grid item xs="auto">
                  {headerMenuList}
                </Grid>
              </Hidden>
              <Grid item xs="auto">
                <Grid container spacing={8} alignItems="center">
                  <Hidden xsDown>
                    <Grid item xs="auto">
                      {socialMedia}
                    </Grid>
                  </Hidden>
                </Grid>
              </Grid>
            </Grid>
            <Hidden lgUp>
              <IconButton
                className={`${headerStyles.mobileMenuButton}`}
                color="inherit"
                aria-label="Menu"
                onClick={this.toggleDrawer(true)}
              >
                <MenuIcon fontSize="large" />
              </IconButton>
            </Hidden>
          </Toolbar>
        </AppBar>
        <LeftDrawer
          className={`${headerStyles.leftDrawer}`}
          open={this.state.openDrawer}
          onClose={this.toggleDrawer(false)}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer(false)}
            onKeyDown={this.toggleDrawer(false)}
          >
            {siteLogo}
            {drawerMenuList}
          </div>
          <div className={`${headerStyles.leftDrawerMiddleSpace}`} />
          {socialMedia}
        </LeftDrawer>
      </React.Fragment>
    );
  }
}

export default Header;
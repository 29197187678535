import React from 'react';
import demoStyles from './index.module.css';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {
  isValidName,
  isValidEmail,
  isValidPhone
} from '../../../../../static/util/validator';

const CssTextField = withStyles({
  root: {
    '& label, & label.Mui-focused': {
      color: '#999',
    },
    '& input, & textarea': {
      color: '#fff',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#999',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#fff',
    },
    '&:hover:not($disabled) .MuiInput-underline:before': {
      borderBottomColor: '#fff',
    },
    paddingRight: '20px'
  }
})(TextField);

class BookADemo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      business: '',
      phoneN: '',
      email: '',
      message: '',
      nameError: false,
      phoneError: false,
      emailError: false,
      contactFormSubmitted: false
    };
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  render() {
    return (
      <React.Fragment>
        {
          (this.state.contactFormSubmitted)
            ? (
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <div>Thank you for your interest. We will get back to you as soon as possible.</div>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={0} className={demoStyles.mainSectionBottomDemoContainer}>
                <Grid item xs={12}>
                  <div className={`${demoStyles.articleTitle} articleTitle`}>Book a Demo</div>
                </Grid>
                <Grid item md={6} xs={12}>
                  <CssTextField
                    fullWidth={true}
                    label="Name"
                    margin="dense"
                    value={this.state.name}
                    error={this.state.nameError}
                    onChange={this.handleChange('name')}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CssTextField
                    fullWidth={true}
                    label="Business"
                    margin="dense"
                    value={this.state.business}
                    onChange={this.handleChange('business')}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CssTextField
                    fullWidth={true}
                    label="Phone Number"
                    margin="dense"
                    value={this.state.phone}
                    error={this.state.phoneError}
                    onChange={this.handleChange('phone')}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CssTextField
                    fullWidth={true}
                    label="Email"
                    margin="dense"
                    value={this.state.email}
                    error={this.state.emailError}
                    onChange={this.handleChange('email')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CssTextField
                    fullWidth={true}
                    multiline={true}
                    label="Message (Optional)"
                    margin="dense"
                    rowsMax={2}
                    value={this.state.message}
                    onChange={this.handleChange('message')}
                  />
                </Grid>
                <Grid item xs={12} style={{ paddingTop: 22 }}>
                  <Button className="m-top-2" style={{ verticalAlign: 'bottom' }}
                    variant="contained" onClick={this.validateAndSubmitContactForm}>
                    Send
                </Button>
                </Grid>
              </Grid>
            )
        }
      </React.Fragment>
    );
  }

  validateAndSubmitContactForm = () => {
    const { name, phone, email } = this.state;
    const isNameValid = isValidName(name);
    const isPhoneValid = isValidPhone(phone);
    const isEmailValid = isValidEmail(email);

    this.setState({
      nameError: !isNameValid,
      phoneError: !isPhoneValid,
      emailError: !isEmailValid
    }, () => {
      if (isNameValid && isPhoneValid && isEmailValid) {
        this.submitContactForm();
      }
    });
  }

  submitContactForm = () => {
    var allValues = {
      name: this.state.name,
      business: this.state.business,
      phone: this.state.phone,
      email: this.state.email,
      message: this.state.message
    }

    fetch('https://api.mimosa.solutions/mms-core/api/v1/admin/message', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(allValues)
    }).then(response => {
      this.setState({
        contactFormSubmitted: true
      });
    });
  }
}

export default BookADemo;
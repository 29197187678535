import React from 'react';
import contactPageStyles from './index.module.css';
import Grid from '@material-ui/core/Grid';
import BookAMeeting from './Meeting';
import BookADemo from './Demo';
import Footer from '../../Footer';

class MainSection extends React.Component {
  render() {
    return (
      <section className={`${contactPageStyles.mainSection} section`}>
        <Grid container spacing={0} className={`${contactPageStyles.mainSectionTop} contentContainer`} alignContent="center">
          <Grid item xs={12} className="textAlignCenter">
            <div className={`${contactPageStyles.sectionTitle} sectionTitle`}>Contact Us</div>
            <div className={`sectionTitleUnderline ul-center`} />
            <div className={`articleBigTitle`}>How Can We Help You?</div>
            <div className={`${contactPageStyles.generalArticle} generalArticle`}>
              Well established or just starting out, we want to hear your story.
            </div>
            <div className={`${contactPageStyles.generalArticle} generalArticle`} style={{ marginBottom: '30px' }}>
              Contact us to find out what we can do for you and your business.
            </div>
          </Grid>
        </Grid>
        <div className={`${contactPageStyles.mainSectionBottom} contentContainer`}>
          <Grid container spacing={0}>
            <Grid item md={6} xs={12}>
              <BookADemo />
            </Grid>
            <Grid item md={6} xs={12}>
              <BookAMeeting />
            </Grid>
          </Grid>
        </div>

        <Footer />
      </section>
    );
  }
}

export default MainSection;
import React from 'react';
import Layout from '../components/Layout';
import ReactFullpage from '@fullpage/react-fullpage';
import MainSection from '../components/Contact/Main';
import { graphql } from 'gatsby';
import {
  TransitionGroup,
  Transition as ReactTransition,
} from "react-transition-group"

const timeout = 1000;

const pluginWrapper = () => {
  require('fullpage.js/vendors/scrolloverflow');
};

class Contact extends React.Component {
  render() {
    const { data } = this.props;
    return (
      <Layout location={this.props.location} title="Contact" showFooter={true}>
        <head>
          <title>{`Contact | ${data.site.siteMetadata.title}`}</title>
          <link rel="canonical" href={`${data.site.siteMetadata.url}/contact/`} />
        </head>
        <TransitionGroup>
          <ReactTransition
            key={'/contact'}
            timeout={{
              enter: timeout,
              exit: timeout,
            }}
          >
            <ReactFullpage
              pluginWrapper={pluginWrapper}
              scrollOverflow
              licenseKey={'AA6575F4-62CE406F-B83ACFDF-DADAA912'}
              verticalCentered={false}
              render={({ state, fullpageApi }) => {
                return (
                  <ReactFullpage.Wrapper>
                    <MainSection />
                  </ReactFullpage.Wrapper>
                );
              }}
            />
          </ReactTransition>
        </TransitionGroup>
      </Layout>
    );
  }
}

export default Contact;

export const query = graphql`
  query ContactPageQuery {
    site {
      siteMetadata {
        title
        url
      }
    }
  }
`;

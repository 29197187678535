import React from 'react';
import meetingStyles from './index.module.css';
import Grid from '@material-ui/core/Grid';


class BookAMeeting extends React.Component {
  render() {
    return (
      <Grid container className={meetingStyles.mainSectionBottomMeetingContainer}>
        <Grid item xs={12}>
          <div className={`${meetingStyles.articleTitle} articleTitle`}>Book a Meeting</div>
        </Grid>
        <Grid item xs={4} className={`${meetingStyles.infoTitle} p-top-20 p-bottom-10`} style={{marginBottom: 150}}>
          Email
        </Grid>
        <Grid item xs={8} className={`${meetingStyles.infoContent} p-top-20 p-bottom-10`} style={{marginBottom: 150}}>
          info@mimosa.solutions
        </Grid>
      </Grid>
    );
  }
}

export default BookAMeeting;
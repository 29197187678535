import React from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import '../Header/header.module.css';
import "./index.css";
import Navigation from '../Navigation';

import m3Logo from '../../../static/assets/img/logo/Logo-M-small.png';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiButton: {
      contained: {
        backgroundColor: '#f8e008',
        color: 'black',
        fontFamily: 'Oswald',
        fontSize: '21px',
        fontWeight: '300',
        minHeight: '50px',
        minWidth: '186px',
        borderRadius: '0px',
        boxShadow: 'none'
      }
    },
    MuiFab: {
      secondary: {
        '&:hover:not($disabled)': {
          backgroundColor: 'transparent',
          color: '#fff',
          border: '1px solid #fff'
        },
        backgroundColor: 'transparent',
        color: '#babbbd',
        border: '1px solid #babbbd'
      }
    }
  }
});

class Layout extends React.Component {

  render() {
    const { children, showFooter } = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        <Navigation LocalTitle={this.props.title} showFooter={showFooter}>
          <React.Fragment>
            <head>
              <link rel="icon" type="image/png" href={m3Logo} />
              <meta name="description" content="Mimosa Membership Management" />
              <meta name="keywords" content="mimosa, membership management, m3 system" />
              <meta name="format-detection" content="telephone=no"></meta>
            </head>
            {children}
          </React.Fragment>
        </Navigation>
      </MuiThemeProvider>
    )
  }
}

export default Layout;
import * as validator from 'validator';

export const isValidBusinessName = (value) => {
  const trimmed = value.trim().replace(/[ ()-/']/g, '');
  
  if (!trimmed) {
    return true;
  } else {
    return validator.isAlphanumeric(trimmed);
  }
};

export const isValidName = (value) => {
  const trimmed = value.trim().replace(/[ ()-/']/g, '');
  
  if (!trimmed) {
    return false;
  } else {
    return validator.isAlpha(trimmed);
  }
};

export const isValidEmail = (email) => {
  if (!email) {
    return false;
  }
  return validator.isEmail(email);
}

export const isValidPhone = (phone) => {
  if (!phone) {
    return false;
  }

  // TODO: support different locales
  return validator.isMobilePhone(phone, 'en-US');
}
